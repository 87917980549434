var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  useRefineEventForNodeEditor,
  useRefineProperties
} from "@/hooks/refineProps";
import { getNewValue } from "@bsgp/form-and-table";
import Markdown from "react-markdown";
import parse from "html-react-parser";
import {
  DateRangePicker,
  DatePicker,
  DateTimePicker,
  TimePicker,
  RadioButton,
  ToolbarSeparator,
  NodeEditor
} from "@/components";
import * as Components from "@/components";
import { getStyleCodeFromProperties, removeInvalidKeys } from "@/lib/utils";
const ComponentBuilder = (props) => {
  const _a = props, {
    type = "Text",
    hiddenData,
    refineEventHookName
  } = _a, restProps = __objRest(_a, [
    "type",
    "hiddenData",
    "refineEventHookName"
  ]);
  const properties = useRefineProperties(props);
  const style = getStyleCodeFromProperties({ properties, props });
  const newValue = getNewValue(props.value, props.conv);
  const newProps = removeInvalidKeys(__spreadProps(__spreadValues(__spreadValues({}, restProps), (type === "MenuButton" || type === "MenuItem") && {
    refineEventHookName
  }), {
    properties: __spreadProps(__spreadValues({}, removeInvalidKeys(properties)), {
      "data-cy": `${type}-${props.name || props.id}`
    }),
    type,
    value: newValue,
    hiddenData,
    label: props.label || "",
    styleClasses: props.styleClasses || [],
    style
  }));
  if (type === "HTML") {
    return parse(newValue || "");
  } else if (type === "Markdown") {
    return /* @__PURE__ */ React.createElement(Markdown, null, newValue);
  } else if (type === "DateTime") {
    return /* @__PURE__ */ React.createElement(DateTimePicker, __spreadValues({}, newProps));
  } else if (type === "Date") {
    return /* @__PURE__ */ React.createElement(DatePicker, __spreadValues({}, newProps));
  } else if (type === "Time") {
    return /* @__PURE__ */ React.createElement(TimePicker, __spreadValues({}, newProps));
  } else if (type === "DateRange") {
    return /* @__PURE__ */ React.createElement(DateRangePicker, __spreadValues({}, newProps));
  } else if (type === "Radio") {
    return /* @__PURE__ */ React.createElement(RadioButton, __spreadValues({}, newProps));
  } else if (type === "FormattedText") {
    return /* @__PURE__ */ React.createElement("div", __spreadValues({}, newProps));
  } else if (type === "Separator") {
    return /* @__PURE__ */ React.createElement(ToolbarSeparator, __spreadValues({}, newProps));
  } else if (type === "NodeEditor") {
    const refinedProps = useRefineEventForNodeEditor(
      __spreadValues({}, newProps),
      newProps
    );
    return /* @__PURE__ */ React.createElement(NodeEditor, __spreadValues({}, refinedProps));
  } else {
    const SingleComponent = Components[type];
    if (SingleComponent) {
      return /* @__PURE__ */ React.createElement(SingleComponent, __spreadValues({}, newProps));
    } else {
      return /* @__PURE__ */ React.createElement("div", null, "Component not found");
    }
  }
};
export default ComponentBuilder;
