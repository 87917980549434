var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { propsRef } from "@/ref";
import { refineEvent } from "@/lib/refineEvent";
import { useTableContext } from "@/contexts/tableContext";
import { getValueFromDataBindingFormat } from "@/lib/utils";
export const useRefineEvent = (properties) => {
  const { fn } = propsRef.current;
  return refineEvent(properties, fn);
};
export const useRefineEventForTableToolbar = (properties) => {
  const { fn } = propsRef.current;
  const { tableProperties, filteredData } = useTableContext();
  const {
    columnProperties: { columns }
  } = tableProperties;
  return refineEvent(
    properties,
    fn,
    {
      columns,
      tableData: filteredData
    },
    { shouldStoreInOEvent: true, shouldSkipLegacyFields: true }
  );
};
export const useRefineEventForTableCell = (properties, props) => {
  const { fn } = propsRef.current;
  const options = {
    rowData: props.rowData
  };
  return refineEvent(properties, fn, options);
};
export const useRefineEventForTreeTable = (properties) => {
  const { fn } = propsRef.current;
  const { tableData, selectedIndices } = useTableContext();
  return refineEvent(
    properties,
    fn,
    {
      dataList: tableData,
      indices: selectedIndices,
      usingPagination: false,
      items: tableData
    },
    { shouldSkipLegacyFields: true, shouldStoreInOEvent: true }
  );
};
export const useRefineEventForCodeEditor = (properties, doNotRefine = false) => {
  if (doNotRefine) return properties;
  const { fn } = propsRef.current;
  return refineEvent(
    properties,
    fn,
    {
      componentCtg: "codeeditors"
    },
    {
      shouldSkipLegacyFields: true
    }
  );
};
export const useRefineEventForNodeEditor = (properties, props) => {
  const { fn } = propsRef.current;
  const options = {
    nextId: props.nextId,
    componentCtg: "nodeeditors"
  };
  return refineEvent(properties, fn, options, {
    shouldSkipLegacyFields: true
  });
};
export const useRefineEventForSection = (properties) => {
  const { fn } = propsRef.current;
  const options = {
    componentCtg: "sections"
  };
  return refineEvent(properties, fn, options, {
    shouldSkipLegacyFields: true
  });
};
export const EVENT_REFINER_MAP = {
  useRefineEvent,
  useRefineEventForTableCell,
  useRefineEventForTableToolbar,
  useRefineEventForTreeTable,
  useRefineEventForCodeEditor,
  useRefineEventForNodeEditor,
  useRefineEventForSection
};
export const useRefineProperties = (props) => {
  const { refineEventHookName = "useRefineEvent" } = props;
  const refineEvent2 = EVENT_REFINER_MAP[refineEventHookName];
  const properties = refineEventHookName === "none" ? props.properties : refineEvent2(props.properties, props);
  return convertLegacyProperties(props, properties);
};
const convertLegacyProperties = (props, properties = {}) => {
  const newBooleanProperties = convertBooleanProperties({ props, properties });
  const valueState = properties.valueState || props.valueState;
  return __spreadValues(__spreadValues(__spreadValues({}, properties), newBooleanProperties), valueState ? { valueState: convertLegacyValueState(valueState) } : {});
};
const convertBooleanProperties = ({ props, properties }) => {
  const legacyKeys = Object.keys(LegacyToNewBooleanPropsMap);
  const filteredProperties = getFilteredLegacyProperties(legacyKeys, props);
  const convertedProperties = transformBoolProperties(filteredProperties);
  return __spreadValues(__spreadValues({}, properties), convertedProperties);
};
const getFilteredLegacyProperties = (legacyKeys, props) => {
  return legacyKeys.reduce((acc, propKey) => {
    const conditionValue = getLegacyBooleanValue({ props, propKey });
    if (conditionValue === void 0) {
      return acc;
    }
    acc[propKey] = conditionValue;
    return acc;
  }, {});
};
const LegacyToNewBooleanPropsMap = {
  editable: "!readonly",
  enabled: "!disabled",
  visible: "visible"
};
const getLegacyBooleanValue = ({ props, propKey }) => {
  const { properties = {}, rowData } = props;
  const rawVal = properties[propKey];
  if (typeof rawVal === "boolean") {
    return rawVal;
  }
  if (typeof rawVal === "string") {
    const result = getValueFromDataBindingFormat(rawVal, rowData);
    return result;
  }
  return void 0;
};
export const transformBoolProperties = (properties) => {
  return Object.entries(properties).reduce((acc, [key, value]) => {
    const newKey = LegacyToNewBooleanPropsMap[key];
    if (newKey.startsWith("!")) {
      acc[newKey.slice(1)] = !value;
      return acc;
    }
    acc[newKey] = value;
    return acc;
  }, {});
};
export function convertLegacyValueState(status) {
  if (!status) return "None";
  switch (status) {
    case "Success":
      return "Positive";
    case "Error":
      return "Negative";
    case "Warning":
      return "Critical";
    default:
      return status;
  }
}
;
