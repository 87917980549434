var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var renderer_exports = {};
__export(renderer_exports, {
  default: () => renderer_default
});
module.exports = __toCommonJS(renderer_exports);
var import_react = __toESM(require("react"));
var import_react_router_dom = require("react-router-dom");
var import_toolkit = require("@reduxjs/toolkit");
var import_form_and_table = require("@bsgp/form-and-table");
var import_ft_react = require("@bsgp/ft-react");
var import_lib_core = require("@bsgp/lib-core");
var import_lib_api = require("@bsgp/lib-api");
var controller = __toESM(require("./controller"));
var import_context = require("./context");
var import_modules = require("./modules");
var import_slice = __toESM(require("../builder/slice"));
var import_slice2 = __toESM(require("./slice"));
var import_ui5 = require("actions/ui5");
var import_lib_core2 = require("@bsgp/lib-core");
var import_cache = require("cache");
const initPage = (meta, props) => {
  const { _dispatch } = props;
  if ((0, import_lib_core.isTruthy)(meta.forms)) {
    const firstSelectedFormId = meta.forms[0].key;
    _dispatch(
      import_slice2.default.actions._selectFormId({ formKey: firstSelectedFormId })
    );
  }
  _dispatch(import_slice2.default.actions._initialSpread({ meta }));
  (0, import_modules.executeFunction)(props, {
    uid: "initialization",
    functions: meta.functions,
    isAsync: true
  });
};
const Renderer = (props) => {
  const { _state, _dispatch, match, history, location, dispatch } = props;
  const { entryPath } = match.params;
  const endPoint = "/g/lc_ui5";
  const { meta, data } = _state;
  import_context.dataRef.current = data;
  const builder = (0, import_form_and_table.formTable)({ wrapForms: meta.wrapForms });
  if (!import_cache.cacheRef.current) {
    import_cache.cacheRef.current = new import_lib_core2.DataCache(3600);
  }
  (0, import_react.useEffect)(() => {
    if (data.messages) {
      setTimeout(() => {
        data.messages.forEach((msg) => {
          dispatch((0, import_ui5.addError)(msg));
        });
      }, 10);
      _dispatch(
        import_slice2.default.actions._overwriteState((draft) => {
          delete draft.messages;
        })
      );
    }
  }, [data.messages]);
  const onFetchMetaByPath = (metadata) => {
    _dispatch(import_slice.default.actions._getMetaData(metadata));
    _dispatch(
      import_slice.default.actions._updateMetaProperty({
        key: "currentPath",
        value: entryPath,
        extra: true
      })
    );
  };
  (0, import_react.useEffect)(() => {
    if (entryPath !== void 0) {
      const entryOriginSet = (0, import_lib_core.tryit)(
        () => import_cache.cacheRef.current.get("EntryOriginSet")
      );
      let foundFromCache = false;
      if (entryOriginSet) {
        for (const entryOrigin of entryOriginSet) {
          if ((0, import_react_router_dom.matchPath)(`/${entryPath}`, { path: entryOrigin, exact: true })) {
            const targetMeta = (0, import_lib_core.tryit)(
              () => import_cache.cacheRef.current.get(`EntryOrigin:${entryOrigin}`)
            );
            if (targetMeta) {
              foundFromCache = true;
              onFetchMetaByPath(targetMeta);
            }
            break;
          }
        }
      }
      import_lib_api.apiHub.get(
        endPoint,
        { path: `/${entryPath}` },
        {
          afterSucceed: (res) => {
            const entryOrigin = (0, import_lib_core.tryit)(() => res.paths[0].origin);
            if (entryOrigin) {
              const entryOriginSet2 = (0, import_lib_core.tryit)(
                () => import_cache.cacheRef.current.get("EntryOriginSet")
              );
              if (entryOriginSet2) {
                entryOriginSet2.add(entryOrigin);
                import_cache.cacheRef.current.set("EntryOriginSet", entryOriginSet2);
              } else {
                import_cache.cacheRef.current.set("EntryOriginSet", /* @__PURE__ */ new Set([entryOrigin]));
              }
              import_cache.cacheRef.current.set(`EntryOrigin:${entryOrigin}`, res);
            }
            onFetchMetaByPath(res);
          },
          afterFailed: () => {
            if ((0, import_modules.checkPreviewMode)(match)) {
              history.replace(`/${entryPath}`, location.state);
            } else history.replace("/");
          }
        },
        { appIsBusy: !foundFromCache }
      );
    }
    return () => {
      _dispatch(
        import_slice.default.actions._updateMetaProperty({
          key: "currentPath",
          value: "",
          extra: true
        })
      );
      _dispatch(import_slice2.default.actions._initAll());
    };
  }, [entryPath]);
  (0, import_react.useEffect)(() => {
    if (meta.extra.currentPath === entryPath) {
      initPage(meta, props);
    }
  }, [meta.extra.currentPath]);
  (0, import_react.useEffect)(() => {
    meta.dialogs.filter((each) => each.ignore !== true).forEach((dialog) => {
      var _a, _b;
      if (((_a = data.dialogs[dialog.key]) == null ? void 0 : _a.isOpen) === true && ((_b = data.dialogs[dialog.key]) == null ? void 0 : _b.initialized) !== true) {
        _dispatch(
          import_slice2.default.actions._updateDialogData({
            dialogKey: dialog.key,
            key: "initialized",
            value: true
          })
        );
        initPage(dialog, props);
      }
    });
  }, [data.dialogs]);
  if (entryPath === meta.extra.currentPath) {
    if ((0, import_lib_core.isTruthy)(meta.headers)) {
      const { headers } = meta;
      const getBtnOptions = (btn) => __spreadValues({
        text: btn.text
      }, (0, import_modules.getProperties)(btn, { props }));
      headers.filter((each) => each.ignore !== true).forEach((header) => {
        const { buttons, links } = header;
        buttons.filter((each) => each.ignore !== true).forEach((button) => {
          if (button.isAction) {
            builder.addFooterAction(`${button.key}`, __spreadValues({
              text: button.text
            }, (0, import_modules.getProperties)(button, {
              props
            })));
          } else {
            if (button.buttons && button.buttons.length > 0) {
              builder.addFooterButton(button.key, [
                getBtnOptions(button),
                ...button.buttons.map((btn) => getBtnOptions(btn))
              ]);
            } else {
              builder.addFooterButton(
                button.key,
                getBtnOptions(button)
              );
            }
          }
        });
        links.filter((each) => each.ignore !== true).forEach((link) => {
          builder.addLink(`${link.key}`, __spreadValues({
            text: link.text,
            url: link.url
          }, (0, import_modules.getProperties)(link, {
            props
          })));
        });
      });
    }
    (0, import_modules.addObjects)(builder, {
      forms: meta.forms,
      tables: meta.tables,
      codeeditors: meta.codeeditors,
      nodeeditors: meta.nodeeditors,
      sections: meta.sections,
      props,
      functions: meta.functions,
      entryPath
    });
    if ((0, import_lib_core.isTruthy)(meta.dialogs)) {
      meta.dialogs.filter((each) => each.ignore !== true).forEach((dialog, dIdx) => {
        const dialogBuilder = (0, import_form_and_table.formTable)();
        (0, import_modules.addObjects)(dialogBuilder, {
          forms: dialog.forms,
          tables: dialog.tables,
          codeeditors: dialog.codeeditors,
          nodeeditors: dialog.nodeeditors,
          sections: dialog.sections,
          functions: dialog.functions,
          props,
          entryPath
        });
        const isValueHelp = !data.dialogs[dialog.key];
        const customProps = (0, import_modules.getProperties)(dialog, {
          props
        });
        const dialogProps = __spreadProps(__spreadValues({}, customProps), {
          isOpen: isValueHelp ? void 0 : (0, import_lib_core.tryit)(() => data.dialogs[dialog.key].isOpen),
          onClose: (fn) => fn.onClose(dialog.key),
          properties: __spreadProps(__spreadValues({}, customProps.properties), {
            title: dialog.title
          }),
          ftData: dialogBuilder.done()
        });
        if (dialog.onConfirm && (!dialog.onCancel || dialog.onCancel.ignore)) {
          dialogProps.onCancel = (fn) => fn.onCancel(dialog.key);
        }
        builder.addDialog(dialog.key, dialogProps);
      });
    }
    if ((0, import_lib_core.isTruthy)(meta.paths[0].title)) {
      meta.title = (0, import_modules.getValueFromBind)(data, meta.paths[0].title);
    }
  }
  if (meta.renderAsReact === true) {
    return /* @__PURE__ */ import_react.default.createElement(
      import_ft_react.FormTable,
      __spreadProps(__spreadValues({}, props), {
        fn: controller,
        title: meta.title,
        data: builder.done()
      })
    );
  } else {
    return /* @__PURE__ */ import_react.default.createElement(
      import_form_and_table.FormTable,
      __spreadProps(__spreadValues({}, props), {
        fn: controller,
        title: meta.title,
        data: builder.done()
      })
    );
  }
};
var renderer_default = (0, import_toolkit.compose)(import_react_router_dom.withRouter)(Renderer);
