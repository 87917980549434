var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useState, useId } from "react";
import { Button } from "@ui5/webcomponents-react";
import { Menu } from "@/components";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { ButtonTypeToDesignMap } from "@/maps/legacyMap";
import Icon from "@/components/Icon";
import { HBox } from "@/components";
function MenuButton(props) {
  const _a = props, {
    style,
    value,
    items,
    properties = {},
    refineEventHookName,
    className
  } = _a, restProps = __objRest(_a, [
    "style",
    "value",
    "items",
    "properties",
    "refineEventHookName",
    "className"
  ]);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [event, setEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen((isOpen2) => !isOpen2);
  };
  const opener = useId();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    __spreadProps(__spreadValues({
      id: opener,
      design: ButtonTypeToDesignMap[properties.type] || "Default"
    }, properties), {
      style: __spreadValues({}, style),
      className,
      onClick: handleOpen
    }),
    /* @__PURE__ */ React.createElement(HBox, { style: { gap: "0.375rem" } }, value, /* @__PURE__ */ React.createElement(Icon, { name: "sap-icon://navigation-down-arrow" }))
  ), /* @__PURE__ */ React.createElement(
    Menu,
    {
      opener,
      open: isOpen,
      onClose: () => setIsOpen(false),
      onItemClick: (event2) => {
        const selectedItem = event2.detail.item;
        const selectedIdx2 = selectedItem.dataset.idx;
        setSelectedIdx(selectedIdx2);
        setEvent(event2);
        setIsOpen(false);
      },
      horizontalAlign: "Center",
      placement: "Bottom",
      verticalAlign: "Center"
    },
    Object.entries(items).map(([key, btn], index) => {
      const curIndex = getIndex(index);
      return /* @__PURE__ */ React.createElement(
        ComponentBuilder,
        __spreadValues(__spreadProps(__spreadValues({}, restProps), {
          key,
          type: "MenuItem",
          "data-idx": curIndex,
          getIndex,
          selectedIdx,
          handleSelectedIdx: setSelectedIdx,
          event,
          setEvent,
          refineEventHookName
        }), btn)
      );
    })
  ));
}
const getIndex = (index, path) => {
  if (path !== void 0) {
    return `${path}.${index}`;
  } else {
    return `${index}`;
  }
};
export default MenuButton;
