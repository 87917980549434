var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useCallback, useEffect } from "react";
import {
  refSetterOpenDialogIds,
  refSetterValueHelpV2Event
} from "@/contexts/dialogContext";
import { refineEventForDialog } from "@/lib/refineEvent";
import { propsRef } from "@/ref";
import { useDialogContext } from "@/contexts/dialogContext";
import Icon from "@/components/Icon";
import { isFunction } from "@bsgp/lib-core";
export const useDialogHooks = () => {
  const { openDialogIds, valueHelpV2Event, selectedRows } = useDialogContext();
  const fn = propsRef.current.fn;
  const manuallyOpenDialog = useCallback(
    (dialogId) => {
      if (openDialogIds.includes(dialogId)) return;
      refSetterOpenDialogIds.current((dialogIds) => [...dialogIds, dialogId]);
    },
    [openDialogIds, refSetterOpenDialogIds]
  );
  const manuallyCloseDialog = useCallback(
    (dialogId) => {
      if (openDialogIds.length === 0) return;
      refSetterOpenDialogIds.current(
        (dialogIds) => dialogIds.filter((id) => id !== dialogId)
      );
    },
    [openDialogIds, refSetterOpenDialogIds]
  );
  const getIsOpen = useCallback(
    ({ isOpenFromProps, dialogId }) => {
      if (isOpenFromProps) return isOpenFromProps;
      return openDialogIds.includes(dialogId);
    },
    [openDialogIds]
  );
  const getDialogEvent = useCallback(
    (props) => {
      const { dialogId } = props;
      const valueHelpEvent = valueHelpV2Event[dialogId];
      const valueHelpEvents = valueHelpEvent ? Object.entries(valueHelpEvent).reduce(
        (acc, [fnKey, fnValue]) => {
          if (isFunction(fnValue)) {
            acc[fnKey] = (event) => fnValue(__spreadProps(__spreadValues({}, event), { items: selectedRows }));
            return acc;
          }
          return acc;
        },
        {}
      ) : {};
      return __spreadValues(__spreadValues({}, refineEventForDialog(props, fn, selectedRows)), valueHelpEvents);
    },
    [valueHelpV2Event, fn, selectedRows]
  );
  const handleCloseDialog = useCallback(
    (props) => {
      const dialogEvent = getDialogEvent(props);
      dialogEvent.onClose && dialogEvent.onClose();
      manuallyCloseDialog(props.dialogId);
    },
    [getDialogEvent, manuallyCloseDialog]
  );
  return {
    registerValueHelpV2Event,
    manuallyOpenDialog,
    manuallyCloseDialog,
    getIsOpen,
    getDialogEvent,
    handleCloseDialog
  };
};
export const useValueHelpV2 = (props) => {
  const { valueHelpV2, valueHelpOnly } = props.properties;
  const { registerValueHelpV2Event: registerValueHelpV2Event2, manuallyOpenDialog } = useDialogHooks();
  useEffect(() => {
    registerValueHelpV2Event2(valueHelpV2);
  }, []);
  if (!valueHelpV2) return {};
  const { dialogId } = valueHelpV2;
  const handleOpenDialog = () => {
    manuallyOpenDialog(dialogId);
  };
  return {
    icon: valueHelpV2 ? /* @__PURE__ */ React.createElement(
      Icon,
      {
        "data-cy": "value-help",
        mode: "Interactive",
        name: "value-help",
        onClick: handleOpenDialog
      }
    ) : null,
    valueHelpProps: valueHelpOnly ? { onClick: handleOpenDialog, readonly: true } : {}
  };
};
const registerValueHelpV2Event = (valueHelpV2) => {
  if (!valueHelpV2) return;
  const _a = valueHelpV2, { dialogId } = _a, functions = __objRest(_a, ["dialogId"]);
  refSetterValueHelpV2Event.current((currentEvent) => {
    return __spreadProps(__spreadValues({}, currentEvent), {
      [dialogId]: functions
    });
  });
};
