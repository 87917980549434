var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";
import MessageStripDesign from "@ui5/webcomponents/dist/types/MessageStripDesign.js";
export const componentNameMap = {
  Input: "sap.m.Input"
};
export const eventPropertyMap = {
  change: "onChange",
  press: "onClick",
  search: "onSearch",
  select: "onSelect",
  selectionFinish: "onSelectionChange"
};
export const dialogEventPropertyMap = __spreadProps(__spreadValues({}, eventPropertyMap), {
  onCancel: "onBeforeCancel"
});
export const hAlignToTextAlignMap = {
  Begin: "start",
  Center: "center",
  End: "end",
  Left: "left",
  Right: "right"
};
export const ButtonTypeToDesignMap = {
  Ghost: ButtonDesign.Default,
  Emphasized: ButtonDesign.Emphasized
};
export const messageStripDesignMap = {
  Error: MessageStripDesign.Negative,
  Success: MessageStripDesign.Positive,
  Warning: MessageStripDesign.Critical
};
