var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useMemo, memo } from "react";
import { ObjectStatus } from "@ui5/webcomponents-react";
import { isDataBinding, removeDataBinding } from "@/lib/utils";
import { convertNumber } from "@bsgp/form-and-table";
import clsx from "clsx";
import { hAlignToTextAlignMap } from "@/maps/legacyMap";
const ObjectNumber = (props) => {
  const _a = props, {
    value,
    properties,
    hAlign,
    rowData = {},
    styleClasses,
    style
  } = _a, rest = __objRest(_a, [
    "value",
    "properties",
    "hAlign",
    "rowData",
    "styleClasses",
    "style"
  ]);
  const newProperties = removeDataBinding(
    properties
  );
  const newUnit = isDataBinding(properties.unit) ? rowData[newProperties.unit] : properties.unit;
  const newNumber = useMemo(
    () => convertNumber(value, newUnit, {
      isAmount: newProperties.isAmount,
      isQuantity: newProperties.isQuantity,
      asA1: newProperties.asA1
    }),
    [rowData, value, newProperties]
  );
  return /* @__PURE__ */ React.createElement(
    ObjectStatus,
    __spreadValues({
      className: clsx(...styleClasses, "ft_display_bottom_dash_on_table"),
      style: __spreadValues({
        width: "100%",
        textAlign: hAlign ? hAlignToTextAlignMap[hAlign] : "start",
        borderBottom: "1px dashed var(--sapIllus_Layering1)"
      }, style)
    }, rest),
    /* @__PURE__ */ React.createElement("span", { style: { fontWeight: "bold" } }, newNumber),
    /* @__PURE__ */ React.createElement(
      "span",
      {
        style: {
          marginLeft: "0.2rem",
          fontSize: "0.8rem"
        }
      },
      newUnit
    )
  );
};
export default memo(ObjectNumber);
