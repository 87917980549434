var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo } from "react";
import { Table, TableRow, TableHeaderRow, TableSelection } from "@/components";
import { useTableContext } from "@/contexts/tableContext";
import TableCellBuilder from "./TableCellBuilder";
import TableHeaderCellBuilder from "./TableHeaderCellBuilder";
import { AnalyticalTableSelectionMode } from "@ui5/webcomponents-react";
import { handleSelection } from "@/hooks/table";
const TableComponentBuilder = ({ header, footer }) => {
  const tableContext = useTableContext();
  const { dataDisplaying, tableProperties, selectedRowKeys } = tableContext;
  const _a = tableProperties, { name, columnProperties, onSelect } = _a, rest = __objRest(_a, ["name", "columnProperties", "onSelect"]);
  const { columns } = columnProperties;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, header, /* @__PURE__ */ React.createElement(
    Table,
    __spreadProps(__spreadValues({}, rest), {
      headerRow: /* @__PURE__ */ React.createElement(TableHeaderRow, { sticky: true }, columns.map((col, idx) => /* @__PURE__ */ React.createElement(
        TableHeaderCellBuilder,
        {
          data: col,
          tableName: name,
          key: `${name}_columns_${idx}`
        }
      ))),
      features: /* @__PURE__ */ React.createElement(
        TableSelection,
        {
          mode: rest.selectionMode,
          slot: "features",
          onChange: (event) => {
            const mode = rest.selectionMode;
            if (!mode || mode === AnalyticalTableSelectionMode.None) {
              return;
            }
            handleSelection(event, tableContext);
          },
          selected: selectedRowKeys
        }
      )
    }),
    dataDisplaying.map((row, idx) => /* @__PURE__ */ React.createElement(
      TableRow,
      {
        key: `TableRow-${idx}`,
        rowKey: `${name}_tableRow_${idx}`,
        "data-idx": idx,
        "data-rowData": JSON.stringify(row)
      },
      /* @__PURE__ */ React.createElement(
        TableCellBuilder,
        __spreadProps(__spreadValues({}, tableProperties), {
          rowData: __spreadProps(__spreadValues({}, dataDisplaying[idx]), { index: idx })
        })
      )
    ))
  ), footer);
};
export default memo(TableComponentBuilder);
