(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bsgp/lib-amount"), require("@bsgp/lib-core"), require("@bsgp/lib-date"), require("@bsgp/lib-hoc"), require("@bsgp/lib-quantity"), require("@reduxjs/toolkit"), require("@sentry/react"), require("@ui5/webcomponents-react-base"), require("@ui5/webcomponents/dist/types/ButtonDesign.js"), require("path"), require("react"), require("spark-md5"), require("ui5-lib-rc"), require("xlsx"));
	else if(typeof define === 'function' && define.amd)
		define(["@bsgp/lib-amount", "@bsgp/lib-core", "@bsgp/lib-date", "@bsgp/lib-hoc", "@bsgp/lib-quantity", "@reduxjs/toolkit", "@sentry/react", "@ui5/webcomponents-react-base", "@ui5/webcomponents/dist/types/ButtonDesign.js", "path", "react", "spark-md5", "ui5-lib-rc", "xlsx"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bsgp/lib-amount"), require("@bsgp/lib-core"), require("@bsgp/lib-date"), require("@bsgp/lib-hoc"), require("@bsgp/lib-quantity"), require("@reduxjs/toolkit"), require("@sentry/react"), require("@ui5/webcomponents-react-base"), require("@ui5/webcomponents/dist/types/ButtonDesign.js"), require("path"), require("react"), require("spark-md5"), require("ui5-lib-rc"), require("xlsx")) : factory(root["@bsgp/lib-amount"], root["@bsgp/lib-core"], root["@bsgp/lib-date"], root["@bsgp/lib-hoc"], root["@bsgp/lib-quantity"], root["@reduxjs/toolkit"], root["@sentry/react"], root["@ui5/webcomponents-react-base"], root["@ui5/webcomponents/dist/types/ButtonDesign.js"], root["path"], root["react"], root["spark-md5"], root["ui5-lib-rc"], root["xlsx"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__883__, __WEBPACK_EXTERNAL_MODULE__228__, __WEBPACK_EXTERNAL_MODULE__91__, __WEBPACK_EXTERNAL_MODULE__491__, __WEBPACK_EXTERNAL_MODULE__258__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__867__, __WEBPACK_EXTERNAL_MODULE__976__, __WEBPACK_EXTERNAL_MODULE__115__, __WEBPACK_EXTERNAL_MODULE__911__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__344__, __WEBPACK_EXTERNAL_MODULE__877__, __WEBPACK_EXTERNAL_MODULE__347__) => {
return 