var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var viewer_exports = {};
__export(viewer_exports, {
  default: () => viewer_default
});
module.exports = __toCommonJS(viewer_exports);
var import_react = __toESM(require("react"));
var import_toolkit = require("@reduxjs/toolkit");
var import_react_router_dom = require("react-router-dom");
var import_form_and_table = require("@bsgp/form-and-table");
var controller = __toESM(require("./controller"));
const Viewer = (props) => {
  (0, import_react.useEffect)(() => {
    controller.getAllList({ getProps: () => props })();
  }, []);
  const builder = (0, import_form_and_table.formTable)({});
  const { _state } = props;
  const { list } = _state.viewer;
  builder.addFooterButton("builder", {
    value: "\uC0DD\uC131",
    icon: "sap-icon://create",
    onPress: (fn) => fn.moveToBuilder()
  }).addFooterButton("refresh", {
    value: "",
    isMain: false,
    icon: "sap-icon://refresh",
    onPress: (fn) => fn.getAllList
  }).addTable("list", {
    usePagination: true,
    onSort: (fn) => fn.onSortTable
  }).addToolbarSearch().addColumn("id", {
    text: "ID",
    width: "7rem",
    component: import_form_and_table.ft.Link({
      onPress: (fn) => fn.moveToBuilderWithPath()
    })
  }).addColumn("description", {
    text: "Description"
  }).addColumn("diffLink", {
    text: "Diff(link)",
    width: "5rem",
    component: [
      import_form_and_table.ft.Link({
        properties: {
          text: "Link",
          href: "{diffLink}",
          target: "_blank",
          visible: ["{= ", "!!$", "{diffLink}", " }"].join("")
        }
      }),
      import_form_and_table.ft.Text({
        properties: {
          text: "n/a",
          visible: ["{= ", "!$", "{diffLink}", " }"].join("")
        }
      })
    ]
  }).addColumn("updatedAt", {
    text: "Updated At"
  }).addColumn("updatedBy", {
    width: "7rem",
    text: "Updated By"
  }).addItems(list.map((each) => __spreadProps(__spreadValues({}, each), {
    diffLink: each.gitPrNumber ? [
      "https://github.com/bsgp/lc5-meta-functions/pull",
      each.gitPrNumber,
      "files"
    ].join("/") : ""
  })));
  return /* @__PURE__ */ import_react.default.createElement(
    import_form_and_table.FormTable,
    __spreadProps(__spreadValues({}, props), {
      fn: controller,
      title: "List",
      data: builder.done()
    })
  );
};
var viewer_default = (0, import_toolkit.compose)(import_react_router_dom.withRouter)(Viewer);
