var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { tryit } from "@bsgp/lib-core";
import { isArray } from "@bsgp/lib-core";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { HBox, VBox, FlexBox } from "@/components";
import { FlexBoxJustifyContent } from "@ui5/webcomponents-react";
function CellBuilder(props) {
  const { column, value, cellName, rowData } = props;
  if (isArray(column.component)) {
    const colComponents = column.component;
    const WrappingBox = column.vertical ? VBox : HBox;
    return /* @__PURE__ */ React.createElement(
      FlexBox,
      {
        name: "wrapLayout-" + cellName,
        style: {
          width: column.width,
          minWidth: column.minWidth,
          maxWidth: column.maxWidth
        }
      },
      /* @__PURE__ */ React.createElement(
        WrappingBox,
        {
          name: cellName,
          style: {
            width: column.width
          },
          alignItems: "Center"
        },
        colComponents.map((comp, idx) => {
          return /* @__PURE__ */ React.createElement(
            ComponentBuilder,
            __spreadProps(__spreadValues({
              key: [cellName, comp.name, idx].filter(Boolean).join("-"),
              name: [cellName, comp.name, idx].filter(Boolean).join("-"),
              conv: column.conv
            }, comp), {
              value,
              rowData,
              refineEventHookName: "useRefineEventForTableCell"
            })
          );
        })
      )
    );
  }
  const colComponent = column.component;
  if (tryit(() => colComponent.type) === "ObjectNumber" && column.hideOnInitial === true) {
    return /* @__PURE__ */ React.createElement(HBox, { name: cellName, justifyContent: FlexBoxJustifyContent.End }, /* @__PURE__ */ React.createElement(
      ComponentBuilder,
      __spreadProps(__spreadValues({}, colComponent), {
        name: cellName,
        properties: __spreadProps(__spreadValues({}, colComponent.properties), {
          visible: value === "0.00" ? false : true
        }),
        value,
        rowData,
        refineEventHookName: "useRefineEventForTableCell"
      })
    ), /* @__PURE__ */ React.createElement(
      ComponentBuilder,
      __spreadProps(__spreadValues({}, colComponent), {
        name: cellName,
        type: "Text",
        properties: {
          visible: value === "0.00" ? true : false
        },
        value,
        rowData,
        refineEventHookName: "useRefineEventForTableCell"
      })
    ));
  }
  return /* @__PURE__ */ React.createElement(
    ComponentBuilder,
    __spreadProps(__spreadValues({
      name: cellName,
      conv: column.conv
    }, colComponent), {
      properties: __spreadValues(__spreadValues({}, column.properties), colComponent && colComponent.properties),
      value,
      rowData,
      refineEventHookName: "useRefineEventForTableCell"
    })
  );
}
export default CellBuilder;
