var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo } from "react";
import { defined } from "@bsgp/lib-core";
import { Text } from "@ui5/webcomponents-react";
import clsx from "clsx";
const FtText = (props) => {
  const _a = props, {
    value,
    tooltip,
    styleClasses = [],
    hiddenData,
    label,
    properties = {},
    children,
    rowData,
    style,
    hAlign
  } = _a, restProps = __objRest(_a, [
    "value",
    "tooltip",
    "styleClasses",
    "hiddenData",
    "label",
    "properties",
    "children",
    "rowData",
    "style",
    "hAlign"
  ]);
  return /* @__PURE__ */ React.createElement(
    Text,
    __spreadValues(__spreadValues({
      title: defined(tooltip, value, ""),
      style: __spreadValues({
        textAlign: "start",
        whiteSpace: "pre-wrap"
      }, style),
      className: clsx(...styleClasses, "sapMText"),
      maxLines: properties.wrapping === false ? 1 : Infinity
    }, properties), restProps),
    getText(value) || getText(children)
  );
};
function getText(targetVal) {
  if (typeof targetVal === "object" && "value" in targetVal) {
    return targetVal.value;
  }
  return targetVal;
}
export default memo(FtText);
