var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, memo } from "react";
import { createPortal } from "react-dom";
import { VBox, Dialog } from "@/components";
import DialogHeaderBuilder from "./DialogHeaderBuilder";
import { useDialogHooks } from "@/hooks/dialog";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { getStyleCodeFromProperties } from "@/lib/utils";
import MainContentBuilder from "../common/MainContentBuilder";
import { constructData } from "@/lib/constructors";
const useStyles = createUseStyles({
  dialog: {
    "&::part(content)": {
      padding: 0
    },
    "& *": {
      boxSizing: "border-box"
    }
  },
  dialogContentPadding: {
    padding: "1rem 0.75rem"
  },
  dialogContentFitContainer: {
    maxHeight: "calc(100% - var(--sapElement_Height) - 1rem - 2px)"
  }
});
const DialogV2Builder = (props) => {
  const {
    properties,
    ftData,
    isOpen: isOpenFromProps,
    fullSize = true,
    disableEsc,
    dialogId
  } = props;
  const { getIsOpen, getDialogEvent } = useDialogHooks();
  const classes = useStyles();
  const isOpenFinal = getIsOpen({ isOpenFromProps, dialogId });
  useEffect(() => {
    if (isOpenFinal === true) {
      const dialogEvent = getDialogEvent(props);
      if (dialogEvent.onRequest) {
        dialogEvent.onRequest();
      }
    }
  }, [isOpenFinal]);
  const newFtData = changeMetaDataForDialog(ftData);
  return createPortal(
    /* @__PURE__ */ React.createElement(
      Dialog,
      __spreadProps(__spreadValues({
        onKeyDown: (event) => {
          if (event.key === "Escape" && disableEsc) {
            event.stopPropagation();
          }
        },
        open: isOpenFinal,
        stretch: fullSize,
        header: /* @__PURE__ */ React.createElement(DialogHeaderBuilder, __spreadValues({}, props)),
        className: clsx("dialog-h-100", classes.dialog)
      }, properties), {
        style: getStyleCodeFromProperties({ properties, props })
      }),
      /* @__PURE__ */ React.createElement(
        VBox,
        {
          fitContainer: "codeeditor" in newFtData || "nodeeditor" in newFtData,
          className: clsx({
            [classes.dialogContentFitContainer]: true,
            [classes.dialogContentPadding]: !("codeeditor" in newFtData)
          })
        },
        /* @__PURE__ */ React.createElement(MainContentBuilder, { data: newFtData })
      )
    ),
    document.body
  );
};
function changeMetaDataForDialog(ftData) {
  const newFtData = __spreadValues({}, ftData);
  if (newFtData.table) {
    Object.keys(newFtData.table).forEach((tableKey) => {
      newFtData.table[tableKey].component.hideDownloadButton = true;
      newFtData.table[tableKey].component.compactSize = true;
    });
  }
  const result = constructData(newFtData);
  return __spreadProps(__spreadValues({}, result), {
    wrapForms: false,
    hasFooter: false,
    doWrapTables: false,
    formOptions: {
      labelSpanS: 12
    }
  });
}
export default memo(DialogV2Builder);
