import { getNormalizedString } from "@/lib/utils";
import { AnalyticalTableSelectionMode } from "@ui5/webcomponents-react";
const ModeConverter = {
  SingleSelect: AnalyticalTableSelectionMode.Single,
  MultiSelect: AnalyticalTableSelectionMode.Multiple,
  None: AnalyticalTableSelectionMode.None
};
function getSelectionMode(each) {
  if (typeof each === "string") {
    return ModeConverter[each] || AnalyticalTableSelectionMode.None;
  }
  let mode;
  if ("mode" in each) {
    mode = each.mode;
  } else if ("properties" in each && "mode" in each.properties) {
    mode = each.properties.mode;
  }
  if (mode) {
    if ([
      AnalyticalTableSelectionMode.Single,
      "SingleSelect",
      "singleselect",
      "SingleSelectMaster",
      "s",
      "single"
    ].includes(mode.toLowerCase())) {
      mode = AnalyticalTableSelectionMode.Single;
    } else if ([
      AnalyticalTableSelectionMode.Multiple,
      "MultiSelect",
      "multiselect",
      "m",
      "multi",
      "multiple"
    ].includes(
      mode.toLowerCase()
    )) {
      mode = AnalyticalTableSelectionMode.Multiple;
    }
  }
  return mode || AnalyticalTableSelectionMode.None;
}
function filterFn(rows, accessor, filterValue) {
  if (!filterValue) return rows;
  return rows.filter((row) => {
    const validRow = row.values ? row.values : row;
    const rowVal = getNormalizedString(validRow[accessor]);
    if (rowVal.includes(getNormalizedString(filterValue))) {
      return true;
    }
    return false;
  });
}
export { getSelectionMode, filterFn };
