var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useEffect } from "react";
import { getSelectionMode, filterFn } from "@/lib/tableUtils";
import {
  AnalyticalTableSelectionBehavior,
  TextAlign
} from "@ui5/webcomponents-react";
import clsx from "clsx";
import { convertNumber } from "@bsgp/form-and-table/fieldComponent";
import { getNewValue, getNewName, isBound } from "@bsgp/form-and-table";
import CellBuilder from "@/builders/table/CellBuilder";
import { Text, Filter } from "@/components";
import { useTableContext } from "@/contexts/tableContext";
import TableGrowingMode from "@ui5/webcomponents-compat/dist/types/TableGrowingMode.js";
import { refSetterSelectedRows } from "@/contexts/dialogContext";
export const useFetchTable = ({ tableName, data }) => {
  const {
    setTableData,
    setTableProperties,
    setFilteredData
  } = useTableContext();
  useEffect(() => {
    const { newMapConv, newColumns } = getMapConvAndColumns(data, tableName);
    const newProperties = getTableProperties(tableName, data);
    setTableProperties(__spreadProps(__spreadValues({}, newProperties), {
      columnProperties: {
        columns: newColumns || [],
        name: tableName
      }
    }));
    const newData = getTableData(data, newMapConv);
    setTableData(newData);
    setFilteredData(newData);
  }, [tableName, data]);
};
export const useSetPage = (pageInfo) => {
  const {
    page,
    setPage,
    setItemsPerPage,
    setEdge,
    setSelectedRowKeys
  } = useTableContext();
  useEffect(() => {
    if (!pageInfo) return;
    const actualInitialPage = pageInfo.initial - 1;
    setPage(actualInitialPage);
    setItemsPerPage(pageInfo.itemsPerPage);
    setEdge(pageInfo.edge);
    setSelectedRowKeys("");
  }, [pageInfo]);
  useEffect(() => {
    setSelectedRowKeys("");
  }, [page]);
};
export const handleSelection = (event, tableContext) => {
  const {
    dataDisplaying,
    tableProperties,
    setSelectedRowKeys
  } = tableContext;
  const { onSelect, name } = tableProperties;
  const selectedKeys = event.target.selected.split(" ");
  const selectedData = [];
  const selectedIndices = [];
  dataDisplaying.forEach((item, dataIdx) => {
    if (!selectedKeys.length) {
      return;
    }
    selectedKeys.forEach((key) => {
      const rowIdx = key.split(`${name}_tableRow_`)[1];
      if (Number(rowIdx) === dataIdx) {
        selectedData.push(item);
        selectedIndices.push(dataIdx);
        selectedKeys.splice(dataIdx, 1);
      }
    });
  });
  refSetterSelectedRows.current(selectedData);
  setSelectedRowKeys(event.target.selected);
  onSelect && onSelect(__spreadProps(__spreadValues({}, event), { selectedData, selectedIndices }));
};
function getMapConvAndColumns(tableInfo, tableName) {
  const newMapConv = {};
  const newColumns = tableInfo.columns.map((col) => {
    if (col.conv) {
      newMapConv[col.name] = col.conv;
    }
    const colComponent = col.component;
    if (!colComponent) return col;
    if (colComponent.type === "ObjectNumber") {
      col.hAlign = TextAlign.End;
    }
    return __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, col), col.showSum ? { footerText: getFooterText(tableInfo, col) } : {}), colComponent.type === "ObjectNumber" ? { hAlign: TextAlign.End } : {}), tableInfo.isTreeTable || tableInfo.useAnalyticalTable ? getAnalyticalTableColumns(col, tableInfo, tableName) : {});
  });
  return { newMapConv, newColumns };
}
function getFooterText(tableInfo, col) {
  const colComponent = col.component;
  let unit = colComponent.properties.unit;
  let unitIsBound = false;
  if (isBound(unit)) {
    unit = unit.replace("{", "").replace("}", "");
    unitIsBound = true;
  }
  const footerText = tableInfo.items.list.reduce((acc, item) => {
    if (unitIsBound) {
      if (acc[item[unit]] === void 0) {
        acc[item[unit]] = 0;
      }
      acc[item[unit]] += parseFloat(item[col.name]);
    } else {
      if (acc[unit] === void 0) {
        acc[unit] = 0;
      }
      acc[unit] += parseFloat(item[col.name]);
    }
    return acc;
  }, {});
  return Object.keys(footerText).map((key) => {
    return [
      convertNumber(footerText[key], key, {
        isAmount: colComponent.properties.isAmount,
        isQuantity: colComponent.properties.isQuantity,
        asA1: colComponent.properties.asA1
      }),
      key
    ].join(" ");
  }).join("\n");
}
function getTableProperties(tableName, tableInfo) {
  if (tableInfo.isTreeTable) {
    return getTreeTableProperties(tableName, tableInfo);
  }
  const selectionMode = getSelectionMode(tableInfo);
  return __spreadProps(__spreadValues({
    name: tableName,
    isTreeTable: false,
    noDataText: "\uB370\uC774\uD130 \uC5C6\uC74C",
    style: { width: "auto" },
    growing: TableGrowingMode.None
  }, tableInfo.properties), {
    selectionMode,
    className: clsx({
      "select-all": true,
      "no-indent-row": true,
      sapUiSizeCompact: tableInfo.compactSize === true,
      "allow-scroll": tableInfo.allowScroll === true,
      "alt-table-style": true
    }),
    onSelect: tableInfo.onSelect,
    onDrop: tableInfo.onDrop,
    onSelectTab: tableInfo.onSelectTab,
    onSort: tableInfo.onSort
  });
}
function getTableData(tableInfo, mapConv) {
  return tableInfo.items.list.map((item) => {
    const newItem = __spreadValues({}, item);
    Object.keys(mapConv).forEach((colName) => {
      newItem[colName] = getNewValue(newItem[colName], mapConv[colName]);
    });
    if (!newItem.properties) {
      newItem.properties = {};
    }
    if (!newItem.properties.row) {
      newItem.properties.row = {};
    }
    if (!newItem.properties.cell) {
      newItem.properties.cell = Object.keys(item || {}).filter((key) => !["properties", "selected", "selectable"].includes(key)).reduce((acc, key) => {
        acc[key] = {};
        return acc;
      }, {});
    }
    return newItem;
  });
}
function getTreeTableProperties(tableName, tableInfo) {
  const mode = getSelectionMode(tableInfo);
  return {
    name: tableName,
    isTreeTable: true,
    selectionBehavior: AnalyticalTableSelectionBehavior.Row,
    selectionMode: mode,
    subRowsKey: "$sub$",
    className: clsx({ "ft-sticky-columns": tableInfo.stickyColumns === true })
  };
}
function getAnalyticalTableColumns(column, tableInfo, tableName) {
  const _a = column, { width, minWidth, maxWidth } = _a, rest = __objRest(_a, ["width", "minWidth", "maxWidth"]);
  const cellName = getNewName([tableName, column.name].join("-"));
  return __spreadProps(__spreadValues({
    disableDragAndDrop: !tableInfo.onDrop,
    accessor: column.name || column.value,
    // Header: column.text,
    Header: /* @__PURE__ */ React.createElement(
      Text,
      __spreadValues({}, tableInfo.headerNoWrap === true ? {
        maxLines: 1,
        emptyIndicatorMode: "On"
      } : {}),
      column.text
    ),
    Cell: (cellProps) => {
      const { cell } = cellProps;
      const value = cell.value;
      const rowData = cell.row.original;
      return /* @__PURE__ */ React.createElement(
        CellBuilder,
        {
          column,
          cellName,
          value,
          rowData
        }
      );
    },
    hAlign: column.hAlign || TextAlign.Begin,
    disableGroupBy: true,
    disableSortBy: true,
    disableResizing: true,
    disableFilters: true,
    disableGlobalFilter: true
  }, rest), {
    Filter: ({ column: column2 }) => /* @__PURE__ */ React.createElement(Filter, { column: column2 }),
    filter: filterFn
  });
}
export default useTableContext;
