var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import FooterComponentsBuilder from "./FooterComponentsBuilder";
import { Toolbar } from "@/components";
import clsx from "clsx";
import ToolbarDesign from "@ui5/webcomponents/dist/types/ToolbarDesign.js";
const FooterBuilder = (props) => {
  return /* @__PURE__ */ React.createElement(
    Toolbar,
    {
      design: ToolbarDesign.Transparent,
      className: clsx({
        sapUiSizeCompact: true
      }),
      style: props.stickyFooter ? {
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "var(--sapContent_Illustrative_Color7)"
      } : {}
    },
    /* @__PURE__ */ React.createElement(FooterComponentsBuilder, __spreadValues({}, props))
  );
};
export default FooterBuilder;
