var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import { createPortal } from "react-dom";
import Button from "@/components/inputs/Button";
import { SortItem, ViewSettingsDialog } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-fiori/dist/SortItem";
import { createSorter } from "@bsgp/lib-core";
import { useTableContext } from "@/contexts/tableContext";
const ViewSettings = (props) => {
  const { tableInfo, properties } = props;
  const { tableProperties } = useTableContext();
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      properties: __spreadValues({
        onClick: () => {
          setIsOpen(true);
        }
      }, properties)
    }
  ), createPortal(
    /* @__PURE__ */ React.createElement(
      ViewSettingsDialog,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
        },
        onConfirm: (event) => {
          const selectedText = event.detail.sortBy;
          const selectedName = tableInfo.columns.find(
            (col) => col.text === selectedText
          ).name;
          const sortDescending = event.detail.sortDescending;
          tableProperties.onSort({
            sorter: createSorter(
              [selectedName],
              sortDescending ? "desc" : "asc"
            )
          });
        },
        sortItems: tableInfo.columns.map((col) => {
          return /* @__PURE__ */ React.createElement(SortItem, { text: col.text, key: col.name, "data-key": col.name });
        })
      }
    ),
    document.body
  ));
};
export default ViewSettings;
