var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { Toolbar } from "@/components";
import { isTruthy } from "@bsgp/lib-core";
import ToolbarComponentBuilder from "@/builders/common/ToolbarComponentBuilder";
import DataCntInfo from "@/components/tables/DataCntInfo";
import ToolbarDesign from "@ui5/webcomponents/dist/types/ToolbarDesign.js";
const ToolbarBuilder = (props) => {
  const { tableName, tableInfo } = props;
  const { toolbar, usePagination } = tableInfo;
  if (!toolbar) return null;
  return /* @__PURE__ */ React.createElement(
    Toolbar,
    __spreadValues({
      name: `${tableName}_header_toolbar`,
      style: __spreadValues({}, !isTruthy(toolbar) ? { display: "none" } : {}),
      design: ToolbarDesign.Transparent,
      className: "sapUiSizeCompact"
    }, isTruthy(toolbar) && __spreadValues({}, toolbar.properties)),
    toolbar.content.map((bar) => {
      return /* @__PURE__ */ React.createElement(
        ToolbarComponentBuilder,
        __spreadProps(__spreadValues(__spreadValues({
          key: `${tableName}_header_toolbar_content_${bar.name}`,
          tableInfo
        }, bar), bar.component), {
          refineEventHookName: "useRefineEventForTableToolbar"
        })
      );
    }).filter(Boolean),
    usePagination && /* @__PURE__ */ React.createElement(DataCntInfo, null)
  );
};
export default ToolbarBuilder;
