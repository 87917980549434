var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useMemo } from "react";
import {
  Card,
  CardHeader,
  AnalyticalCardHeader
} from "@ui5/webcomponents-react";
import { tryit } from "@bsgp/lib-core";
import { useRefineProperties } from "@/hooks/refineProps";
import { MetaSorter } from "@/lib/metaSorter";
import SectionItemBuilder from "@/builders/section/SectionItemBuilder";
import ComponentBuilder from "../common/ComponentBuilder";
import { Icon } from "@/components";
function getHeader(headerType, type, headerComponent) {
  const headerData = headerComponent.data[0];
  switch (headerType) {
    case "AnalyticalCardHeader":
      return /* @__PURE__ */ React.createElement(
        AnalyticalCardHeader,
        __spreadProps(__spreadValues({}, headerData.properties), {
          titleText: headerData.title,
          unitOfMeasurement: headerData.unit,
          value: headerData.value
        }),
        headerData.components.map((component) => {
          return /* @__PURE__ */ React.createElement(ComponentBuilder, __spreadValues({ key: component.name }, component));
        })
      );
    case "CardHeader":
      return /* @__PURE__ */ React.createElement(
        CardHeader,
        __spreadProps(__spreadValues({}, headerData.properties), {
          titleText: headerData.title,
          avatar: headerData.icon ? /* @__PURE__ */ React.createElement(Icon, { name: headerData.icon }) : null
        })
      );
    default:
      return /* @__PURE__ */ React.createElement(
        SectionItemBuilder,
        {
          slot: "header",
          components: [headerComponent],
          sectionType: type
        }
      );
  }
}
const Builder = (props) => {
  const refinedProperties = useRefineProperties(props);
  const { type } = props;
  const sorter = new MetaSorter(props);
  const orderedComponents = useMemo(
    () => sorter.getOrderedLayout(),
    [props]
  );
  const headerType = tryit(() => orderedComponents[0].data[0].sectionType);
  const [headerComponent, ...contentComponents] = orderedComponents;
  return /* @__PURE__ */ React.createElement(
    Card,
    __spreadProps(__spreadValues({}, refinedProperties), {
      header: getHeader(headerType, type, headerComponent)
    }),
    /* @__PURE__ */ React.createElement(
      SectionItemBuilder,
      {
        components: contentComponents,
        sectionType: type
      }
    )
  );
};
export default Builder;
