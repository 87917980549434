import React, { createContext, useMemo, useState } from "react";
const initialState = {
  tableData: [],
  setTableData: (tableData) => {
  },
  page: 0,
  setPage: (page) => {
  },
  filteredData: [],
  setFilteredData: (filteredData) => {
  },
  tableProperties: {
    columnProperties: {
      columns: []
    }
  },
  setTableProperties: (newProperties) => {
  },
  itemsPerPage: 0,
  setItemsPerPage: (itemsPerPage) => {
  },
  edge: 2,
  setEdge: (edge) => {
  },
  selectedIndices: [],
  setSelectedIndices: () => {
  },
  dataDisplaying: [],
  selectedRowKeys: "",
  setSelectedRowKeys: () => {
  }
};
export const TableContext = createContext(initialState);
export const TableContextProvider = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableProperties, setTableProperties] = useState({
    columnProperties: {
      columns: []
    }
  });
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [edge, setEdge] = useState(2);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const dataDisplaying = useMemo(() => {
    return itemsPerPage > 0 ? filteredData.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : filteredData;
  }, [itemsPerPage, filteredData, page]);
  return /* @__PURE__ */ React.createElement(
    TableContext.Provider,
    {
      value: {
        tableData,
        setTableData,
        tableProperties,
        setTableProperties,
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        edge,
        setEdge,
        filteredData,
        setFilteredData,
        selectedIndices,
        setSelectedIndices,
        dataDisplaying,
        selectedRowKeys,
        setSelectedRowKeys
      }
    },
    props.children
  );
};
export const useTableContext = () => React.useContext(TableContext);
