var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo } from "react";
import { clone } from "@bsgp/lib-core";
import { LineChart } from "@ui5/webcomponents-react-charts";
import ChartPlaceholder from "./ChartPlaceholder";
import { useRefineProperties } from "@/hooks/refineProps";
const Builder = (props) => {
  const _a = props, {
    value,
    dimensions,
    measures
  } = _a, restProps = __objRest(_a, [
    "value",
    "dimensions",
    "measures"
  ]);
  const refinedRestProps = useRefineProperties(__spreadValues({}, restProps));
  const newDimensions = dimensions.map((dim) => {
    const newDim = clone(dim);
    delete newDim.name;
    delete newDim.properties;
    delete newDim.type;
    return __spreadValues({
      accessor: dim.name
    }, newDim);
  });
  const newMeasures = measures.map((mes) => {
    const newMes = clone(mes);
    delete newMes.name;
    delete newMes.properties;
    delete newMes.type;
    return __spreadValues({
      accessor: mes.name
    }, newMes);
  });
  return /* @__PURE__ */ React.createElement(
    LineChart,
    __spreadValues({
      dataset: value || [],
      dimensions: newDimensions,
      measures: newMeasures,
      ChartPlaceholder
    }, refinedRestProps)
  );
};
export default memo(Builder);
