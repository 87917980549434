var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Label } from "@/components";
import { isObject } from "@bsgp/lib-core";
import { getComponentName } from "@/lib/utils";
function LabelBuilder(_a) {
  var _b = _a, {
    hasMultiComponents,
    element,
    elName
  } = _b, rest = __objRest(_b, [
    "hasMultiComponents",
    "element",
    "elName"
  ]);
  if (!element.field.label) return null;
  let labelFor = element.name;
  if (hasMultiComponents) {
    const components = element.field.component;
    const idx = components.findIndex((item) => item.type === "Input");
    labelFor = getComponentName({
      elName,
      fieldComponent: element.field.component[Math.max(idx, 0)]
    });
  }
  if (isObject(element.field.label)) {
    const labelObj = element.field.label;
    const labelText = `${labelObj.text}:`;
    return /* @__PURE__ */ React.createElement(Label, __spreadValues(__spreadValues({ name: `${elName}_label`, for: labelFor }, labelObj), rest), labelText);
  } else {
    const labelText = `${element.field.label}:`;
    return /* @__PURE__ */ React.createElement(
      Label,
      __spreadValues({
        name: `${elName}_label`,
        for: labelFor,
        required: element.field.required || false
      }, rest),
      labelText
    );
  }
}
export default React.memo(LabelBuilder);
