var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo } from "react";
import {
  AnalyticalTable,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior
} from "@ui5/webcomponents-react";
import { tryit } from "@bsgp/lib-core";
import { useTableContext } from "@/contexts/tableContext";
const AnalyticalTableBuilder = (props) => {
  const {
    dataDisplaying,
    tableProperties,
    setSelectedIndices
  } = useTableContext();
  const { header, footer } = props;
  const _a = tableProperties, { columnProperties } = _a, rest = __objRest(_a, ["columnProperties"]);
  const columns = columnProperties.columns;
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    AnalyticalTable,
    __spreadProps(__spreadValues({
      filterable: true,
      scaleWidthMode: AnalyticalTableScaleWidthMode.Grow,
      selectionBehavior: AnalyticalTableSelectionBehavior.RowSelector,
      minRows: 1,
      header,
      sortable: true
    }, rest), {
      columns,
      data: dataDisplaying,
      alternateRowColor: props.alternateRowColors,
      onRowSelect: (event) => {
        const isSelected = tryit(() => event.detail.row.isSelected);
        const isAllSelected = event.detail.allRowsSelected;
        const selectedRow = Object.keys(event.detail.selectedRowIds);
        setSelectedIndices(selectedRow);
        console.log("you can add some row select logic here");
        console.log("isSelected : ", isSelected);
        console.log("isAllSelected : ", isAllSelected);
        console.log("selectedRow : ", selectedRow);
      }
    })
  ), footer);
};
export default memo(AnalyticalTableBuilder);
