var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { Toolbar } from "@/components";
import { ToolbarSpacer } from "@ui5/webcomponents-react-compat";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import OptionCustomBuilder from "./OptionCustomBuilder";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
const useStyles = createUseStyles({
  styleForSpacer: {
    '& [data-component-name="ToolbarChildContainer"]:has(.spacer)': {
      flex: "1 1 auto"
    }
  }
});
const EditorToolbarBuilder = (props) => {
  const {
    buttons,
    ceId,
    isFullScreen,
    showCustomOption
  } = props;
  const styles = useStyles();
  return /* @__PURE__ */ React.createElement(
    Toolbar,
    {
      className: clsx("sapUiSizeCompact", styles.styleForSpacer),
      design: isFullScreen ? "Info" : "Solid"
    },
    /* @__PURE__ */ React.createElement(FullScreenButton, __spreadValues({}, props)),
    buttons.map((btn) => {
      const btnVal = Array.isArray(btn) ? btn[0] : btn;
      const isMultiple = Array.isArray(btn) && btn.length > 1;
      const ceToolbarContentKey = [
        ceId,
        btnVal.name || btnVal.component.name
      ].join("_");
      if (isMultiple) {
        return /* @__PURE__ */ React.createElement(
          ToolbarComponentBuilder,
          {
            type: "MenuButton",
            value: btnVal.text || btnVal.value,
            properties: __spreadValues(__spreadValues(__spreadValues({}, btnVal.properties), btnVal.component.properties), btnVal.icon ? { icon: btnVal.icon } : {}),
            key: ceToolbarContentKey,
            items: btn.filter((each, index) => index > 0).reduce((acc, each, index) => {
              acc[`${ceToolbarContentKey}_menuitem_${index}`] = {
                value: each.text || each.value,
                properties: __spreadValues(__spreadValues({
                  icon: each.icon,
                  press: each.onPress
                }, each.properties), each.component.properties)
              };
              return acc;
            }, {})
          }
        );
      } else {
        return /* @__PURE__ */ React.createElement(
          ToolbarComponentBuilder,
          __spreadProps(__spreadValues(__spreadValues({}, btn), btn.component), {
            key: ceToolbarContentKey
          })
        );
      }
    }),
    showCustomOption && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ToolbarSpacer, null), /* @__PURE__ */ React.createElement(OptionCustomBuilder, __spreadValues({}, props)))
  );
};
const FullScreenButton = (props) => {
  const {
    isFullScreen,
    handleFullScreen
  } = props;
  return /* @__PURE__ */ React.createElement(
    ToolbarComponentBuilder,
    {
      type: "Button",
      properties: {
        icon: !isFullScreen ? "sap-icon://full-screen" : "sap-icon://exit-full-screen",
        onClick: handleFullScreen
      },
      refineEventHookName: "none"
    }
  );
};
export default EditorToolbarBuilder;
