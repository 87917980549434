var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React from "react";
import { Text, Toolbar, ToolbarSpacer } from "@/components";
import Button from "@/components/inputs/Button";
import Image from "@/components/inputs/Image";
import { useDialogHooks } from "@/hooks/dialog";
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign.js";
const DialogHeaderBuilder = (props) => {
  const {
    properties,
    title,
    beginButtonText,
    hideBeginButton,
    headerImageUrl
  } = props;
  const { getDialogEvent, handleCloseDialog } = useDialogHooks();
  const { onConfirm, onBeforeCancel } = getDialogEvent(props);
  const titleText = title || properties.title;
  return /* @__PURE__ */ React.createElement(Toolbar, { className: "sapUiSizeCompact" }, !hideBeginButton && /* @__PURE__ */ React.createElement(
    Button,
    {
      value: beginButtonText || "\uD655\uC778",
      properties: {
        design: ButtonDesign.Emphasized,
        onClick: (event) => __async(void 0, null, function* () {
          if (onConfirm) {
            const result = yield onConfirm(event);
            if (result === false) {
              return;
            }
          }
          handleCloseDialog(props);
        })
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      value: "\uB2EB\uAE30",
      properties: {
        design: ButtonDesign.Negative,
        onClick: (event) => __async(void 0, null, function* () {
          if (onBeforeCancel) {
            const result = yield onBeforeCancel(event);
            if (result === false) {
              return;
            }
          }
          handleCloseDialog(props);
        })
      }
    }
  ), titleText && /* @__PURE__ */ React.createElement(Text, { value: titleText }), headerImageUrl && /* @__PURE__ */ React.createElement(ToolbarSpacer, null), headerImageUrl && /* @__PURE__ */ React.createElement(Image, { value: headerImageUrl, height: "var(--sapElement_Height)" }));
};
export default DialogHeaderBuilder;
