var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var formTable_exports = {};
__export(formTable_exports, {
  formTable: () => formTable
});
module.exports = __toCommonJS(formTable_exports);
var import_functions = require("./lib/functions");
var import_lib_core = require("@bsgp/lib-core");
function addButton(key, options, targetObj) {
  const isMultiple = (0, import_lib_core.isArray)(options) && options.length > 1;
  if (isMultiple) {
    targetObj[key] = {
      value: options[0].text,
      component: {
        type: "MenuButton",
        properties: __spreadValues(__spreadValues(__spreadProps(__spreadValues({
          icon: options[0].icon
        }, options[0].properties), {
          useDefaultActionOnly: true
        }), options[0].isMain === false ? { type: window.sap.m.ButtonType.Ghost } : { type: window.sap.m.ButtonType.Emphasized }), options[0].onPress ? {
          buttonMode: window.sap.m.MenuButtonMode.Split,
          defaultAction: options[0].onPress
        } : {})
      },
      items: options.filter((each, index) => index > 0).reduce((acc, each, index) => {
        acc[`${key}_menuitem_${index}`] = {
          value: each.text,
          properties: __spreadProps(__spreadValues({
            icon: each.icon
          }, each.properties), {
            press: each.onPress
          })
        };
        return acc;
      }, {})
    };
  } else {
    const oneOptions = (0, import_lib_core.isArray)(options) ? options[0] : options;
    if (["FileUploader", "Uploader"].includes(oneOptions.type)) {
      targetObj[key] = {
        component: {
          type: "FileUploader",
          properties: __spreadValues({
            icon: oneOptions.icon,
            buttonOnly: true,
            buttonText: oneOptions.text || oneOptions.value,
            change: oneOptions.onPress,
            style: (0, import_lib_core.tryit)(() => oneOptions.properties.type) || window.sap.m.ButtonType.Ghost
          }, oneOptions.properties)
        }
      };
    } else {
      const buttonType = oneOptions.isMain === true ? window.sap.m.ButtonType.Emphasized : window.sap.m.ButtonType.Ghost;
      targetObj[key] = {
        value: oneOptions.text || oneOptions.value,
        component: {
          type: "Button",
          confirmMessage: oneOptions.confirmMessage,
          properties: __spreadValues({
            press: oneOptions.onPress,
            icon: oneOptions.icon,
            type: oneOptions.type || buttonType
          }, oneOptions.properties)
        }
      };
    }
    if (oneOptions.description) {
      targetObj[`${key}__desc`] = {
        value: oneOptions.description,
        component: {
          type: "Text"
        }
      };
    }
  }
}
function formTableUtil() {
  const util = {
    addForm: (data, key, options) => {
      if (!data.form) {
        data.form = {};
      }
      if (!key) {
        return data;
      }
      data.form[key] = __spreadValues({ containers: {} }, options);
      return data;
    },
    addContainer: (data, formKey, key, options) => {
      if (!key) {
        return data;
      }
      if (formKey === false) {
        return data;
      }
      if (!formKey) {
        if (!data.form.containers) {
          data.form.containers = {};
        }
        data.form.containers[key] = options;
        return data;
      }
      if (!data.form[formKey].containers) {
        data.form[formKey].containers = {};
      }
      data.form[formKey].containers[key] = __spreadProps(__spreadValues({}, options), { elements: {} });
      return data;
    },
    addField: (data, formKey, containerKey, key, options) => {
      if (!key) {
        return data;
      }
      if (formKey === false || containerKey === false) {
        return data;
      }
      if (!formKey) {
        if (data.form.containers) {
          if (containerKey) {
            if (!data.form.containers[containerKey].elements) {
              data.form.containers[containerKey].elements = {};
            }
            data.form.containers[containerKey].elements[key] = options;
            return data;
          }
          if (!data.form.containers.elements) {
            data.form.containers.elements = {};
          }
          data.form.containers.elements[key] = options;
          return data;
        }
        data.form[key] = options;
        return data;
      }
      if (!containerKey) {
        if (!data.form[formKey].containers) {
          data.form[formKey].containers = {};
        }
        if (!data.form[formKey].containers) {
          data.form[formKey].containers = {};
        }
        data.form[formKey].containers[key] = options;
        return data;
      }
      if (!data.form[formKey].containers[containerKey].elements) {
        data.form[formKey].containers[containerKey].elements = {};
      }
      data.form[formKey].containers[containerKey].elements[key] = options;
      return data;
    },
    addTable: (data, key, options = {}) => {
      if (!data.table) {
        data.table = {};
      }
      if (!key) {
        return data;
      }
      data.table[key] = {
        component: __spreadValues({
          columns: {},
          items: []
        }, options)
      };
      if (data.table[key].component.usePagination !== void 0) {
        if (data.table[key].component.usePagination === true) {
          data.table[key].component.usePagination = {};
        }
        if ((0, import_lib_core.isObject)(data.table[key].component.usePagination)) {
          data.table[key].component.usePagination = __spreadValues(__spreadValues({}, { initial: 1, itemsPerPage: 20, edge: 2 }), data.table[key].component.usePagination);
        }
      }
      return data;
    },
    addToolbarButton: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }
      if (!data.table[tableKey].component.toolbar.content) {
        data.table[tableKey].component.toolbar.content = {};
      }
      addButton(key, options, data.table[tableKey].component.toolbar.content);
      return data;
    },
    addToolbarAction: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }
      if (!data.table[tableKey].component.toolbar.actions) {
        data.table[tableKey].component.toolbar.actions = {};
      }
      data.table[tableKey].component.toolbar.actions[key] = {
        value: options.text,
        confirmMessage: options.confirmMessage,
        properties: __spreadProps(__spreadValues({}, options.properties), {
          press: options.onPress
        })
      };
      return data;
    },
    addToolbarSearch: (data, tableKey, key, options) => {
      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }
      const searchKey = ["search", key].filter(Boolean).join("_");
      data.table[tableKey].component.toolbar[searchKey] = {
        value: options.value,
        targetColumns: options.targetColumns,
        properties: (0, import_functions.removeUndefinedKeys)(__spreadProps(__spreadValues({}, options.properties), {
          change: options.onChange,
          search: options.onSearch
        }))
      };
      return data;
    },
    addColumn: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.table[tableKey].component.columns) {
        data.table[tableKey].component.columns = {};
      }
      data.table[tableKey].component.columns[key] = __spreadProps(__spreadValues({}, options), {
        properties: __spreadValues({
          width: options.width
        }, options.properties)
      });
      return data;
    },
    addItems: (data, tableKey, list = []) => {
      data.table[tableKey].component.items = list;
      return data;
    },
    addFooterButton: (data, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.footer) {
        data.footer = {};
      }
      addButton(key, options, data.footer);
      return data;
    },
    addFooterAction: (data, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.footer) {
        data.footer = {};
      }
      if (!data.footer.actions) {
        data.footer.actions = {};
      }
      data.footer.actions[key] = {
        value: options.text || options.value,
        confirmMessage: options.confirmMessage,
        properties: __spreadValues({
          press: options.onPress,
          icon: options.icon
        }, options.properties)
      };
      return data;
    },
    addLink: (data, key, options) => {
      if (!data.historyLinks) {
        data.historyLinks = [];
      }
      data.historyLinks.push(options);
      return data;
    },
    addCode: (data, key, options) => {
      if (!data.codeeditor) {
        data.codeeditor = {};
      }
      const codeeditor = __spreadValues({}, options);
      if (codeeditor.properties === void 0) {
        codeeditor.properties = {};
      }
      if (options.onChange) {
        codeeditor.properties = __spreadProps(__spreadValues({}, codeeditor.properties), {
          change: options.onChange
        });
      }
      if (options.type) {
        codeeditor.properties.type = options.type;
      }
      if (codeeditor.properties.syntaxHints === void 0) {
        codeeditor.properties.syntaxHints = false;
      }
      data.codeeditor[key || "codeeditor"] = codeeditor;
      return data;
    },
    addNode: (data, key, options) => {
      if (!data.nodeeditor) {
        data.nodeeditor = {};
      }
      const nodeeditor = __spreadValues({}, options);
      if (nodeeditor.properties === void 0) {
        nodeeditor.properties = {};
      }
      if (options.onChange) {
        nodeeditor.properties = __spreadProps(__spreadValues({}, nodeeditor.properties), {
          change: options.onChange
        });
      }
      if (options.type) {
        nodeeditor.properties.type = options.type;
      }
      data.nodeeditor[key || "nodeeditor"] = nodeeditor;
      return data;
    },
    addSection: (data, key, options) => {
      if (!data.section) {
        data.section = {};
      }
      if (!key) {
        return data;
      }
      data.section[key] = __spreadValues({ components: {} }, options);
      return data;
    },
    addDialog: (data, key, options) => {
      if (!key) {
        return data;
      }
      if (!data.dialogV2) {
        data.dialogV2 = {};
      }
      data.dialogV2[key] = __spreadProps(__spreadValues({
        properties: {},
        ftData: {}
      }, options), {
        fullSize: options.fullSize === void 0 ? true : options.fullSize === true ? true : false
      });
      return data;
    }
  };
  return util;
}
function formTable(options = {}) {
  let data = __spreadValues({}, options);
  const util = formTableUtil();
  const chain = {
    addForm: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addForm(data, key, options2);
      chain.formKey = key;
      chain.containerKey = void 0;
      return chain;
    },
    addContainer: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addContainer(data, chain.formKey, key, options2);
      chain.containerKey = key;
      return chain;
    },
    addField: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addField(
        data,
        chain.formKey,
        chain.containerKey,
        key,
        options2
      );
      return chain;
    },
    addDialog: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addDialog(data, key, options2);
      return chain;
    },
    addTable: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addTable(data, key, options2);
      chain.tableKey = key;
      return chain;
    },
    addSection: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addSection(data, key, options2);
      return chain;
    },
    addToolbarButton: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addToolbarButton(data, chain.tableKey, key, options2);
      return chain;
    },
    addToolbarAction: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addToolbarAction(data, chain.tableKey, key, options2);
      return chain;
    },
    addToolbarSearch: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addToolbarSearch(data, chain.tableKey, key, options2);
      return chain;
    },
    addColumn: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addColumn(data, chain.tableKey, key, options2);
      return chain;
    },
    addItems: (items) => {
      data = util.addItems(data, chain.tableKey, items);
      return chain;
    },
    addFooterButton: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addFooterButton(data, key, options2);
      return chain;
    },
    addFooterAction: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addFooterAction(data, key, options2);
      return chain;
    },
    addLink: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addLink(data, key, options2);
      return chain;
    },
    addCode: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addCode(data, key, options2);
      return chain;
    },
    addNode: (...args) => {
      const [key, options2] = (0, import_functions.extractArgs)(args);
      data = util.addNode(data, key, options2);
      return chain;
    },
    done: () => {
      return data;
    }
  };
  return chain;
}
