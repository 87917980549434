var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { Toolbar } from "@/components";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import { useNodeProps } from "@/hooks/nodeEditor";
import ToolbarDesign from "@ui5/webcomponents/dist/types/ToolbarDesign.js";
const ToolbarBuilder = (props) => {
  const { toolbar } = props;
  const { nodes } = useNodeProps(props);
  if (!toolbar) return null;
  const { content, properties } = toolbar;
  const nextId = nodes.length + 1;
  return /* @__PURE__ */ React.createElement(
    Toolbar,
    __spreadProps(__spreadValues({
      design: ToolbarDesign.Transparent
    }, properties), {
      className: "sapUiSizeCompact"
    }),
    content.map((bar) => {
      return /* @__PURE__ */ React.createElement(
        ToolbarComponentBuilder,
        __spreadProps(__spreadValues(__spreadValues({
          key: bar.name
        }, bar), bar.component), {
          nextId,
          refineEventHookName: "useRefineEventForNodeEditor"
        })
      );
    })
  );
};
export default ToolbarBuilder;
