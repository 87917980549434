var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import ReactFlow, {
  Background,
  Controls
} from "reactflow";
import "reactflow/dist/style.css";
import Node from "@/components/editors/Node";
import { useNodeProps } from "@/hooks/nodeEditor";
const nodeTypes = {
  custom: Node
};
const NodeEditor = (props) => {
  const nodeEditorProps = useNodeProps(props);
  const { style } = props;
  return /* @__PURE__ */ React.createElement("div", { style }, /* @__PURE__ */ React.createElement(
    ReactFlow,
    __spreadProps(__spreadValues({}, nodeEditorProps), {
      fitView: true,
      snapToGrid: true,
      nodeDragThreshold: 1,
      "data-cy": "node-editor",
      nodeTypes
    }),
    /* @__PURE__ */ React.createElement(Background, null),
    /* @__PURE__ */ React.createElement(Controls, null)
  ));
};
export default NodeEditor;
