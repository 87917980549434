var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  Form
} from "@ui5/webcomponents-react";
import { formRef } from "@/ref";
import { clone, merge } from "@bsgp/lib-core";
import { removeUndefinedKeys } from "@bsgp/form-and-table";
const defaultLayout = {
  columnsXL: 4,
  columnsL: 4,
  columnsM: 2,
  columnsS: 1,
  labelSpanXL: 1,
  labelSpanL: 4,
  labelSpanM: 3,
  labelSpanS: 12
};
const calculateFormLayout = (formObject) => {
  const defaultLS = formObject.labelSpanM || 4;
  let sizeOptions = merge(removeUndefinedKeys({
    columnsXL: formObject.columnsXL,
    columnsL: formObject.columnsL,
    columnsM: formObject.columnsM,
    columnsS: formObject.columnsS,
    labelSpanS: formObject.labelSpanS,
    labelSpanM: formObject.labelSpanM,
    labelSpanL: formObject.labelSpanL,
    labelSpanXL: formObject.labelSpanXL
  }), defaultLayout);
  if (formObject.sizeV2) {
    sizeOptions = merge(sizeOptions, {
      columnsM: 3,
      labelSpanM: 4,
      columnsL: 4
    });
  } else {
    if (formObject.containers.length === 1) {
      sizeOptions = merge(sizeOptions, {
        columnsS: 1,
        columnsM: 1,
        columnsL: 1,
        columnsXL: 1,
        labelSpanS: defaultLS,
        labelSpanM: defaultLS,
        labelSpanL: defaultLS,
        labelSpanXL: defaultLS
      });
    }
  }
  const result = {
    layout: [],
    labelSpan: []
  };
  ["S", "M", "L", "XL"].forEach((key) => {
    result.layout.push(`${key}${sizeOptions[`columns${key}`]}`);
    result.labelSpan.push(`${key}${sizeOptions[`labelSpan${key}`]}`);
  });
  return {
    layout: result.layout.join(" "),
    labelSpan: result.labelSpan.join(" ")
  };
};
const FtForm = (props) => {
  const _a = props, {
    className,
    containers,
    style,
    namedForm,
    sizeV2,
    tightContainers,
    compactSize,
    staticContext
  } = _a, restProps = __objRest(_a, [
    "className",
    "containers",
    "style",
    "namedForm",
    "sizeV2",
    "tightContainers",
    "compactSize",
    "staticContext"
  ]);
  const layoutInfo = calculateFormLayout(__spreadProps(__spreadValues({}, restProps), {
    containers,
    namedForm,
    sizeV2
  }));
  const newProps = clone(restProps);
  delete newProps.inactive;
  Object.keys(defaultLayout).forEach((key) => {
    delete newProps[key];
  });
  return /* @__PURE__ */ React.createElement(
    "form",
    __spreadValues({
      onSubmit: (event) => {
        event.preventDefault();
      }
    }, newProps),
    /* @__PURE__ */ React.createElement(
      Form,
      __spreadValues({
        ref: formRef,
        "data-cy": `form-${props.name}`,
        style: __spreadValues({
          padding: "0.5rem 0",
          overflowX: "auto"
        }, style),
        className,
        layout: layoutInfo.layout,
        labelSpan: layoutInfo.labelSpan
      }, restProps)
    )
  );
};
export default FtForm;
